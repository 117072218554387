<template>
  <div>
    Empty
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";

export default {
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cüzdan Yönetimi" },
      { title: "Para Çekme İşlemleri" }
    ]);
  }
};
</script>
